exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-ameehacks-js": () => import("./../../../src/pages/projects/ameehacks.js" /* webpackChunkName: "component---src-pages-projects-ameehacks-js" */),
  "component---src-pages-projects-bmedsci-js": () => import("./../../../src/pages/projects/bmedsci.js" /* webpackChunkName: "component---src-pages-projects-bmedsci-js" */),
  "component---src-pages-projects-diseaseio-js": () => import("./../../../src/pages/projects/diseaseio.js" /* webpackChunkName: "component---src-pages-projects-diseaseio-js" */),
  "component---src-pages-projects-flow-js": () => import("./../../../src/pages/projects/flow.js" /* webpackChunkName: "component---src-pages-projects-flow-js" */),
  "component---src-pages-projects-fpas-js": () => import("./../../../src/pages/projects/fpas.js" /* webpackChunkName: "component---src-pages-projects-fpas-js" */),
  "component---src-pages-projects-hdmsc-js": () => import("./../../../src/pages/projects/hdmsc.js" /* webpackChunkName: "component---src-pages-projects-hdmsc-js" */),
  "component---src-pages-projects-pillbox-js": () => import("./../../../src/pages/projects/pillbox.js" /* webpackChunkName: "component---src-pages-projects-pillbox-js" */),
  "component---src-pages-projects-rtp-js": () => import("./../../../src/pages/projects/rtp.js" /* webpackChunkName: "component---src-pages-projects-rtp-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-wip-js": () => import("./../../../src/pages/wip.js" /* webpackChunkName: "component---src-pages-wip-js" */)
}

